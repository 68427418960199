
import { ref, defineComponent, onMounted, reactive, onUnmounted, computed } from 'vue'
import InputElement from '@/components/UI/Input.vue'
import Dropdown from '@/components/UI/Dropdown.vue'
import toggleableContent from '@/components/toggleableContent.vue'
import ConvertToCommas from '@/filters/ConvertToCommas'
import Loader from '@/components/UI/Loader.vue'
import { superAdminCompanyActionTypes } from '@/store/superAdminCompany/actions'
import { CompanyActionTypes } from '@/store/company/actions'
import { UsersActionTypes } from '@/store/users/actions'
import { useStore } from '@/store'
import Button from '@/components/UI/Button.vue'
import { MutationTypes } from '@/store/company/mutations'
import { superAdminMutationTypes } from '@/store/superAdminCompany/mutations'
import { useRouter } from 'vue-router'
import { useRoute } from 'vue-router'
export default defineComponent({
  name: 'Home',
  setup() {
    const route = useRoute()
    const store = useStore()
    const router = useRouter()
    const file = ref('')
    const selectedRow = ref()
    const loader = computed(() => store.state.superAdminCompany.loader)
    const companyError = computed(() => store.state.superAdminCompany.companyError)
    const superAdminCompanyId = computed(() => store.state.superAdminCompany.superAdminCompanyId)
    const companyDetailsState = computed(() => store.state.company.getCompanyById)
    const companyLeaseRate = computed(() => store.state.superAdminCompany.companyLeaseRate)
    const getAllUsers = computed(() => store.state.users.getAllUsers)
    const leaseRateError = computed(() => store.state.superAdminCompany.leaseRateError)
    const sectionToggleValues = reactive({ value: [true, true, true] })
    const companyDetailsErrors = reactive({})
    const leaseRateErrors = reactive({})
    const companyDetails = reactive({
      name: '',
      email: '',
      phoneNumber: '',
      address: '',
      postalCode: '',
      city: '',
      userAgreementCopyEmail: [],
      quotationRequestEmail: [],
      logo: {}
    })
    const leaseRateDetails = reactive({
      value: {
        leaserateOperator: '+',
        leaserateValue: 0,
        leaserateType: '%',
        fuelOperator: '-',
        fuelValue: 0,
        fuelType: '$',
        months36: 0,
        months48: 0,
        months60: 0,
        biggerTyres: 0
      }
    })
    const typeItems = reactive(['%', '€'])
    const operatorItems = reactive(['+', '-'])
    const getCompanyById = async () => {
      await store.dispatch(CompanyActionTypes.GET_COMPANYBYID, { companyId: route.params.id })
      companyDetails.name = companyDetailsState.value.name
      companyDetails.email = companyDetailsState.value.email
      companyDetails.phoneNumber = companyDetailsState.value.phoneNumber
      companyDetails.address = companyDetailsState.value.address
      companyDetails.postalCode = companyDetailsState.value.postalCode
      companyDetails.city = companyDetailsState.value.city
      if (companyDetailsState.value.logo)
        file.value = `data:${companyDetailsState.value.logoType};base64,${companyDetailsState.value.logo}`
      if (
        companyDetailsState.value.userAgreementCopyEmail == null ||
        !companyDetailsState.value.userAgreementCopyEmail.length
      ) {
        companyDetails.userAgreementCopyEmail = [{ email: '', type: 'add' }]
      } else {
        companyDetails.userAgreementCopyEmail = companyDetailsState.value.userAgreementCopyEmail.map((item) => {
          return { email: item, type: 'delete' }
        })
        companyDetails.userAgreementCopyEmail.push({ email: '', type: 'add' })
      }

      if (
        companyDetailsState.value.quotationRequestEmail == null ||
        !companyDetailsState.value.quotationRequestEmail.length
      ) {
        companyDetails.quotationRequestEmail = [{ email: '', type: 'add' }]
      } else {
        companyDetails.quotationRequestEmail = companyDetailsState.value.quotationRequestEmail.map((item) => {
          return { email: item, type: 'delete' }
        })
        companyDetails.quotationRequestEmail.push({ email: '', type: 'add' })
      }
    }
    const onColumnInput = (option) => {
      if (option['type'] == 'agreementMail') {
        selectedRow.value = companyDetails.userAgreementCopyEmail
      } else if (option['type'] == 'quotationEmail') {
        selectedRow.value = companyDetails.quotationRequestEmail
      }
      selectedRow.value[option['index']][option.keyName] = option['item']
    }
    const getUserById = async () => {
      await store.dispatch(UsersActionTypes.GET_ALLUSERS, {
        companyId: route.params.id,
        query: { page: 1, withRoles: 2 }
      })
    }
    const getLeaseRate = async () => {
      await store.dispatch(superAdminCompanyActionTypes.GET_COMPANY_LEASERATE, { companyId: route.params.id })
      if (Object.keys(companyLeaseRate.value).length) {
        leaseRateDetails.value.leaserateOperator = companyLeaseRate.value.leaserateOperator
        leaseRateDetails.value.leaserateValue = companyLeaseRate.value.leaserateValue
        leaseRateDetails.value.leaserateType =
          companyLeaseRate.value.leaserateType == '$' ? '€' : companyLeaseRate.value.leaserateType
        leaseRateDetails.value.fuelOperator = companyLeaseRate.value.fuelOperator
        leaseRateDetails.value.fuelValue = companyLeaseRate.value.fuelValue
        leaseRateDetails.value.fuelType = companyLeaseRate.value.fuelType == '$' ? '€' : companyLeaseRate.value.fuelType
        leaseRateDetails.value.months36 = companyLeaseRate.value.months36
        leaseRateDetails.value.months48 = companyLeaseRate.value.months48
        leaseRateDetails.value.months60 = companyLeaseRate.value.months60
        leaseRateDetails.value.biggerTyres = companyLeaseRate.value.biggerTyres
      }
    }
    onMounted(async () => {
      if (route.params.id) {
        await getCompanyById()
        await getUserById()
        await getLeaseRate()
      }
    })
    const fileChange = (event) => {
      const fileDetails = event.target.files
      companyDetails['logo'] = fileDetails[0]
      file.value = URL.createObjectURL(fileDetails[0])
    }
    const removeImage = () => {
      const blah = document.querySelector('#blah') as HTMLImageElement
      if (blah != null) {
        blah.src = ''
      }
      file.value = ''
      companyDetails['logo'] = {}
      store.dispatch(superAdminCompanyActionTypes.DELETE_COMPANY_LOGO, { companyId: route.params.id })
    }
    const validatePhoneNumber = (value) => {
      // validate dutch numbers
      const regex = new RegExp(
        '(^[+][0-9]{2}|^[+][0-9]{2}(0)|^([+][0-9]{2})(0)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9-s]{10}$)'
      )
      return regex.test(value)
    }
    const validateEmail = (value) => {
      // eslint-disable-next-line no-useless-escape
      const regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}')
      return regex.test(value)
    }
    const scrollToSection = (sectionId) => {
      const element = document.querySelector(`#${sectionId}`) as HTMLElement
      element.scrollIntoView({
        behavior: 'smooth'
      })
    }
    const removeType = (type) => {
      const tempEmail = JSON.parse(JSON.stringify(type))
      const emailArr = []
      tempEmail.forEach((item) => {
        if (item.email) emailArr.push(item.email.toString())
      })
      return emailArr
    }
    const validateSections = () => {
      delete companyDetailsErrors['nameError']
      delete companyDetailsErrors['emailError']
      delete companyDetailsErrors['emailValidationError']
      delete companyDetailsErrors['phoneNumberError']
      delete companyDetailsErrors['phoneNumberValidationError']
      delete companyDetailsErrors['addressError']
      delete companyDetailsErrors['postalCodeError']
      delete companyDetailsErrors['cityError']
      delete companyDetailsErrors['agreementEmailError']
      delete companyDetailsErrors['quotationEmailError']
      let sectionId = ''
      if (!companyDetails.name) {
        companyDetailsErrors['nameError'] = 'required'
        sectionId = 'companyDetails'
      }
      if (!companyDetails.email) {
        companyDetailsErrors['emailError'] = 'required'
        sectionId = 'companyDetails'
      }
      if (companyDetails.email && !validateEmail(companyDetails.email)) {
        companyDetailsErrors['emailValidationError'] = 'required'
        sectionId = 'companyDetails'
      }
      if (!companyDetails.phoneNumber) {
        companyDetailsErrors['phoneNumberError'] = 'required'
        sectionId = 'companyDetails'
      }
      if (companyDetails.phoneNumber && !validatePhoneNumber(companyDetails.phoneNumber)) {
        companyDetailsErrors['phoneNumberValidationError'] = 'required'
        sectionId = 'companyDetails'
      }
      if (!companyDetails.address) {
        companyDetailsErrors['addressError'] = 'required'
        sectionId = 'companyDetails'
      }
      if (!companyDetails.postalCode) {
        companyDetailsErrors['postalCodeError'] = 'required'
        sectionId = 'companyDetails'
      }
      if (!companyDetails.city) {
        companyDetailsErrors['cityError'] = 'required'
        sectionId = 'companyDetails'
      }
      if (companyDetails.userAgreementCopyEmail.length) {
        const tmpCheck = removeType(companyDetails.userAgreementCopyEmail)
        tmpCheck.forEach((mail) => {
          if (!validateEmail(mail)) companyDetailsErrors['agreementEmailError'] = 'required'
        })
      }
      if (companyDetails.quotationRequestEmail.length) {
        const tmpCheck = removeType(companyDetails.quotationRequestEmail)
        tmpCheck.forEach((mail) => {
          if (!validateEmail(mail)) companyDetailsErrors['quotationEmailError'] = 'required'
        })
      }
      if (Object.getOwnPropertyNames(companyDetailsErrors).length === 0) {
        return true
      } else {
        scrollToSection(sectionId)
      }
    }
    const onInputCompanyDetails = (value, key) => {
      companyDetails[key] = value
    }
    const selectleaseRateOperator = (value) => {
      leaseRateDetails.value['leaserateOperator'] = value
    }
    const selectLeaseRateType = (value) => {
      leaseRateDetails.value['leaserateType'] = value
    }
    const selectfuelOperator = (value) => {
      leaseRateDetails.value['fuelOperator'] = value
    }
    const selectfuelType = (value) => {
      leaseRateDetails.value['fuelType'] = value
    }
    const onUpdateLeaseRateDetails = (value, key) => {
      leaseRateDetails.value[key] = value
    }
    const convertToNumber = (selectedOption) => {
      return parseFloat(selectedOption.replace(',', '.'))
    }
    const updateLeaseRate = async () => {
      leaseRateDetails.value.leaserateValue = convertToNumber(leaseRateDetails.value.leaserateValue.toString())
      leaseRateDetails.value.leaserateType =
        leaseRateDetails.value.leaserateType == '€' ? '$' : leaseRateDetails.value.leaserateType
      leaseRateDetails.value.fuelValue = convertToNumber(leaseRateDetails.value.fuelValue.toString())
      leaseRateDetails.value.fuelType = leaseRateDetails.value.fuelType == '€' ? '$' : leaseRateDetails.value.fuelType
      leaseRateDetails.value.months36 = convertToNumber(leaseRateDetails.value.months36.toString())
      leaseRateDetails.value.months48 = convertToNumber(leaseRateDetails.value.months48.toString())
      leaseRateDetails.value.months60 = convertToNumber(leaseRateDetails.value.months60.toString())
      leaseRateDetails.value.biggerTyres = convertToNumber(leaseRateDetails.value.biggerTyres.toString())
      store.dispatch(superAdminCompanyActionTypes.UPDATE_COMPANY_LEASERATE, {
        companyId: route.params.id,
        leaseRateId: companyLeaseRate.value.id,
        body: leaseRateDetails.value
      })
    }
    const validateFloat = (value) => {
      // eslint-disable-next-line no-useless-escape
      const regex = /^\d+([\,]\d+)*([\.]\d+)?$/gm
      return regex.test(value)
    }
    const validateLeaseRate = () => {
      delete leaseRateErrors['leaserateValueError']
      delete leaseRateErrors['fuelValueError']
      delete leaseRateErrors['months36Error']
      delete leaseRateErrors['months48Error']
      delete leaseRateErrors['months60Error']
      delete leaseRateErrors['biggerTyresError']
      if (leaseRateDetails.value.leaserateValue && !validateFloat(leaseRateDetails.value.leaserateValue)) {
        leaseRateErrors['leaserateValueError'] = 'required'
      }
      if (leaseRateDetails.value.fuelValue && !validateFloat(leaseRateDetails.value.fuelValue)) {
        leaseRateErrors['fuelValueError'] = 'required'
      }
      if (leaseRateDetails.value.months36 && !validateFloat(leaseRateDetails.value.months36)) {
        leaseRateErrors['months36Error'] = 'required'
      }
      if (leaseRateDetails.value.months48 && !validateFloat(leaseRateDetails.value.months48)) {
        leaseRateErrors['months48Error'] = 'required'
      }
      if (leaseRateDetails.value.months60 && !validateFloat(leaseRateDetails.value.months60)) {
        leaseRateErrors['months60Error'] = 'required'
      }
      if (leaseRateDetails.value.biggerTyres && !validateFloat(leaseRateDetails.value.biggerTyres)) {
        leaseRateErrors['biggerTyresError'] = 'required'
      }
      if (Object.getOwnPropertyNames(leaseRateErrors).length === 0) {
        return true
      }
    }
    const saveCompany = async () => {
      const formData = new FormData()
      formData.append('name', JSON.stringify(companyDetails.name))
      formData.append('email', JSON.stringify(companyDetails.email))
      formData.append('phoneNumber', JSON.stringify(companyDetails.phoneNumber))
      formData.append('address', JSON.stringify(companyDetails.address))
      formData.append('postalCode', JSON.stringify(companyDetails.postalCode))
      formData.append('city', JSON.stringify(companyDetails.city))
      formData.append('logo', companyDetails.logo as File)
      formData.append('userAgreementCopyEmail', JSON.stringify(removeType(companyDetails.userAgreementCopyEmail)))
      formData.append('quotationRequestEmail', JSON.stringify(removeType(companyDetails.quotationRequestEmail)))
      formData.append('_method', 'PUT')
      await store.dispatch(superAdminCompanyActionTypes.UPDATE_COMPANY, { companyId: route.params.id, body: formData })
    }
    const discardCompany = () => {
      router.push({ name: 'Dashboard' })
    }
    const closePopup = () => {
      store.commit(MutationTypes.SET_ISCOMPANYUPDATED, false)
    }
    const toggleSection = (index) => {
      const closeAllOther = sectionToggleValues.value.map(function (item, itemIndex) {
        if (itemIndex === index) {
          item = !item
        } else {
          item = false
        }
        return item
      })
      sectionToggleValues.value = closeAllOther
    }
    const goToNext = async (type) => {
      let sectionID = ''
      if (type === 'step1' && validateSections()) {
        await saveCompany()
        if (companyError.value == '') {
          sectionID = 'adjustLeaseRateDetails'
        }
      } else if (type === 'step2' && validateLeaseRate()) {
        await updateLeaseRate()
        if (leaseRateError.value == '') {
          sectionID = 'firstAdminCompanyDetails'
        }
      }
      if (sectionID !== '')
        setTimeout(() => {
          scrollToSection(sectionID)
        }, 300)
    }
    const addRow = (item) => {
      if (item['type'] == 'agreementMail') {
        selectedRow.value = companyDetails.userAgreementCopyEmail
        selectedRow.value.push({
          email: '',
          type: 'add'
        })
      } else if (item['type'] == 'quotationEmail') {
        selectedRow.value = companyDetails.quotationRequestEmail
        selectedRow.value.push({
          email: '',
          type: 'add'
        })
      }
      selectedRow.value[item['index']]['type'] = 'delete'
    }
    const deleteRow = (item) => {
      if (item['type'] == 'agreementMail') {
        selectedRow.value = companyDetails.userAgreementCopyEmail
      } else if (item['type'] == 'quotationEmail') {
        selectedRow.value = companyDetails.quotationRequestEmail
      }
      selectedRow.value.splice(item['index'], 1)
    }
    onUnmounted(() => {
      store.commit(superAdminMutationTypes.SET_COMPANY_ERROR, '')
      store.commit(superAdminMutationTypes.SET_USER_ERROR, '')
      store.commit(superAdminMutationTypes.SET_SUPERADMIN_COMPANYID, null)
      store.commit(superAdminMutationTypes.SET_LEASE_RATE_ERROR, '')
      store.commit(superAdminMutationTypes.SET_COMPANY_LEASE_RATE, {})
    })
    return {
      file,
      fileChange,
      removeImage,
      companyDetails,
      loader,
      saveCompany,
      discardCompany,
      onInputCompanyDetails,
      companyDetailsErrors,
      closePopup,
      operatorItems,
      leaseRateDetails,
      selectleaseRateOperator,
      onUpdateLeaseRateDetails,
      ConvertToCommas,
      typeItems,
      selectLeaseRateType,
      selectfuelOperator,
      selectfuelType,
      companyError,
      superAdminCompanyId,
      getAllUsers,
      toggleSection,
      sectionToggleValues,
      goToNext,
      leaseRateError,
      leaseRateErrors,
      addRow,
      deleteRow,
      onColumnInput
    }
  },
  components: {
    InputElement,
    Loader,
    Button,
    Dropdown,
    toggleableContent
  }
})
