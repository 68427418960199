
import { ref, defineComponent, reactive, onUnmounted, computed } from 'vue'
import InputElement from '@/components/UI/Input.vue'
import OptionsCheckboxes from '@/components/UI/OptionsCheckboxes.vue'
import toggleableContent from '@/components/toggleableContent.vue'
import Dropdown from '@/components/UI/Dropdown.vue'
import ConvertToCommas from '@/filters/ConvertToCommas'
import Loader from '@/components/UI/Loader.vue'
import { superAdminCompanyActionTypes } from '@/store/superAdminCompany/actions'
import { useStore } from '@/store'
import Button from '@/components/UI/Button.vue'
import { MutationTypes } from '@/store/company/mutations'
import { superAdminMutationTypes } from '@/store/superAdminCompany/mutations'
import { useRouter } from 'vue-router'
export default defineComponent({
  name: 'Home',
  setup() {
    const store = useStore()
    const router = useRouter()
    const file = ref('')
    const selectedRow = ref()
    const loader = computed(() => store.state.superAdminCompany.loader)
    const companyError = computed(() => store.state.superAdminCompany.companyError)
    const userError = computed(() => store.state.superAdminCompany.userError)
    const leaseRateError = computed(() => store.state.superAdminCompany.leaseRateError)
    const superAdminCompanyId = computed(() => store.state.superAdminCompany.superAdminCompanyId)
    const sectionToggleValues = reactive({ value: [true, false, false] })
    const companyDetailsErrors = reactive({})
    const leaseRateErrors = reactive({})
    const isCompanyAdminDisabled = ref(true)
    const firstCompanyAdminDetailsErrors = reactive({})
    const companyDetails = reactive({
      name: '',
      email: '',
      phoneNumber: '',
      address: '',
      postalCode: '',
      city: '',
      userAgreementCopyEmail: [{ email: '', type: 'add' }],
      quotationRequestEmail: [{ email: '', type: 'add' }],
      logo: {}
    })
    const firstCompanyAdminDetails = reactive({
      name: '',
      email: '',
      userStatus: 'selector available',
      employeeNumber: '',
      roles: [2] as Array<number>,
      street: '',
      postalCode: '',
      city: '',
      phoneNumber: '',
      country: 1,
      incomeTaxPercentage: null,
      houseNumber: '',
      privateUse: 0,
      expectedMileage: 0,
      carPolicy: 0,
      leaseCategory: 0,
      allowedFuelType: [] as Array<string>,
      licensePlate: null,
      leaseValidUntil: null,
      sendInvite: 0
    })
    const leaseRateDetails = reactive({
      value: {
        leaserateOperator: '+',
        leaserateValue: 0,
        leaserateType: '%',
        fuelOperator: '-',
        fuelValue: 0,
        fuelType: '$',
        months36: 0,
        months48: 0,
        months60: 0,
        biggerTyres: 0
      }
    })
    const typeItems = reactive(['%', '€'])
    const operatorItems = reactive(['+', '-'])
    const fileChange = (event) => {
      const fileDetails = event.target.files
      companyDetails['logo'] = fileDetails[0]
      file.value = URL.createObjectURL(fileDetails[0])
    }
    const removeImage = () => {
      const blah = document.querySelector('#blah') as HTMLImageElement
      if (blah != null) {
        blah.src = ''
      }
      file.value = ''
      companyDetails['logo'] = {}
    }
    const validatePhoneNumber = (value) => {
      // validate dutch numbers
      const regex = new RegExp(
        '(^[+][0-9]{2}|^[+][0-9]{2}(0)|^([+][0-9]{2})(0)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9-s]{10}$)'
      )
      return regex.test(value)
    }
    const validateEmail = (value) => {
      // eslint-disable-next-line no-useless-escape
      const regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}')
      return regex.test(value)
    }
    const validateAddress = (value) => {
      // validate address
      const regex = new RegExp('[a-z0-9]+[a-z0-9]')
      return regex.test(value)
    }
    const scrollToSection = (sectionId) => {
      const element = document.querySelector(`#${sectionId}`) as HTMLElement
      element.scrollIntoView({
        behavior: 'smooth'
      })
    }
    const onColumnInput = (option) => {
      if (option['type'] == 'agreementMail') {
        selectedRow.value = companyDetails.userAgreementCopyEmail
      } else if (option['type'] == 'quotationEmail') {
        selectedRow.value = companyDetails.quotationRequestEmail
      }
      selectedRow.value[option['index']][option.keyName] = option['item']
    }
    const removeType = (type) => {
      const tempEmail = JSON.parse(JSON.stringify(type))
      const emailArr = []
      tempEmail.forEach((item) => {
        if (item.email) emailArr.push(item.email.toString())
      })
      return emailArr
    }
    const validateFirstCompanyAdmin = () => {
      delete firstCompanyAdminDetailsErrors['nameError']
      delete firstCompanyAdminDetailsErrors['emailError']
      delete firstCompanyAdminDetailsErrors['emailValidationError']
      delete firstCompanyAdminDetailsErrors['streetError']
      delete firstCompanyAdminDetailsErrors['houseNumberError']
      delete firstCompanyAdminDetailsErrors['postalCodeError']
      delete firstCompanyAdminDetailsErrors['cityError']
      delete firstCompanyAdminDetailsErrors['phoneNumberError']
      delete firstCompanyAdminDetailsErrors['phoneNumberValidationError']
      if (!firstCompanyAdminDetails.name) {
        firstCompanyAdminDetailsErrors['nameError'] = 'required'
      }
      if (!firstCompanyAdminDetails.email) {
        firstCompanyAdminDetailsErrors['emailError'] = 'required'
      }
      if (firstCompanyAdminDetails.email && !validateEmail(firstCompanyAdminDetails.email)) {
        firstCompanyAdminDetailsErrors['emailValidationError'] = 'required'
      }
      if (!firstCompanyAdminDetails.street) {
        firstCompanyAdminDetailsErrors['streetError'] = 'required'
      }
      if (!firstCompanyAdminDetails.houseNumber) {
        firstCompanyAdminDetailsErrors['houseNumberError'] = 'required'
      }
      if (!firstCompanyAdminDetails.postalCode) {
        firstCompanyAdminDetailsErrors['postalCodeError'] = 'required'
      }
      if (!firstCompanyAdminDetails.city) {
        firstCompanyAdminDetailsErrors['cityError'] = 'required'
      }
      if (!firstCompanyAdminDetails.phoneNumber) {
        firstCompanyAdminDetailsErrors['phoneNumberError'] = 'required'
      }
      if (firstCompanyAdminDetails.phoneNumber && !validatePhoneNumber(firstCompanyAdminDetails.phoneNumber)) {
        firstCompanyAdminDetailsErrors['phoneNumberValidationError'] = 'required'
      }
      if (Object.getOwnPropertyNames(firstCompanyAdminDetailsErrors).length === 0) {
        return true
      }
    }
    const validateSections = () => {
      delete companyDetailsErrors['nameError']
      delete companyDetailsErrors['emailError']
      delete companyDetailsErrors['emailValidationError']
      delete companyDetailsErrors['phoneNumberError']
      delete companyDetailsErrors['phoneNumberValidationError']
      delete companyDetailsErrors['addressError']
      delete companyDetailsErrors['addressValidationError']
      delete companyDetailsErrors['postalCodeError']
      delete companyDetailsErrors['cityError']
      delete companyDetailsErrors['agreementEmailError']
      delete companyDetailsErrors['quotationEmailError']
      if (!companyDetails.name) {
        companyDetailsErrors['nameError'] = 'required'
      }
      if (!companyDetails.email) {
        companyDetailsErrors['emailError'] = 'required'
      }
      if (companyDetails.email && !validateEmail(companyDetails.email)) {
        companyDetailsErrors['emailValidationError'] = 'required'
      }
      if (!companyDetails.phoneNumber) {
        companyDetailsErrors['phoneNumberError'] = 'required'
      }
      if (companyDetails.phoneNumber && !validatePhoneNumber(companyDetails.phoneNumber)) {
        companyDetailsErrors['phoneNumberValidationError'] = 'required'
      }
      if (!companyDetails.address) {
        companyDetailsErrors['addressError'] = 'required'
      }
      if (companyDetails.address && !validateAddress(companyDetails.address)) {
        companyDetailsErrors['addressValidationError'] = 'required'
      }
      if (!companyDetails.postalCode) {
        companyDetailsErrors['postalCodeError'] = 'required'
      }
      if (!companyDetails.city) {
        companyDetailsErrors['cityError'] = 'required'
      }
      if (companyDetails.userAgreementCopyEmail.length) {
        const tmpCheck = removeType(companyDetails.userAgreementCopyEmail)
        tmpCheck.forEach((mail) => {
          if (!validateEmail(mail)) companyDetailsErrors['agreementEmailError'] = 'required'
        })
      }
      if (companyDetails.quotationRequestEmail.length) {
        const tmpCheck = removeType(companyDetails.quotationRequestEmail)
        tmpCheck.forEach((mail) => {
          if (!validateEmail(mail)) companyDetailsErrors['quotationEmailError'] = 'required'
        })
      }
      if (Object.getOwnPropertyNames(companyDetailsErrors).length === 0) {
        return true
      }
    }
    const validateFloat = (value) => {
      // eslint-disable-next-line no-useless-escape
      const regex = /^\d+([\,]\d+)*([\.]\d+)?$/gm
      return regex.test(value)
    }
    const validateLeaseRate = () => {
      delete leaseRateErrors['leaserateValueError']
      delete leaseRateErrors['fuelValueError']
      delete leaseRateErrors['months36Error']
      delete leaseRateErrors['months48Error']
      delete leaseRateErrors['months60Error']
      delete leaseRateErrors['biggerTyresError']
      if (leaseRateDetails.value.leaserateValue && !validateFloat(leaseRateDetails.value.leaserateValue)) {
        leaseRateErrors['leaserateValueError'] = 'required'
      }
      if (leaseRateDetails.value.fuelValue && !validateFloat(leaseRateDetails.value.fuelValue)) {
        leaseRateErrors['fuelValueError'] = 'required'
      }
      if (leaseRateDetails.value.months36 && !validateFloat(leaseRateDetails.value.months36)) {
        leaseRateErrors['months36Error'] = 'required'
      }
      if (leaseRateDetails.value.months48 && !validateFloat(leaseRateDetails.value.months48)) {
        leaseRateErrors['months48Error'] = 'required'
      }
      if (leaseRateDetails.value.months60 && !validateFloat(leaseRateDetails.value.months60)) {
        leaseRateErrors['months60Error'] = 'required'
      }
      if (leaseRateDetails.value.biggerTyres && !validateFloat(leaseRateDetails.value.biggerTyres)) {
        leaseRateErrors['biggerTyresError'] = 'required'
      }
      if (Object.getOwnPropertyNames(leaseRateErrors).length === 0) {
        return true
      }
    }
    const onInputCompanyDetails = (value, key) => {
      companyDetails[key] = value
    }
    const onInputFirstCompanyAdmin = (value, key) => {
      firstCompanyAdminDetails[key] = value
    }
    const selectleaseRateOperator = (value) => {
      leaseRateDetails.value['leaserateOperator'] = value
    }
    const selectLeaseRateType = (value) => {
      leaseRateDetails.value['leaserateType'] = value
    }
    const selectfuelOperator = (value) => {
      leaseRateDetails.value['fuelOperator'] = value
    }
    const selectfuelType = (value) => {
      leaseRateDetails.value['fuelType'] = value
    }
    const onUpdateLeaseRateDetails = (value, key) => {
      leaseRateDetails.value[key] = value
    }
    const convertToNumber = (selectedOption) => {
      return parseFloat(selectedOption)
    }
    const addLeaseRates = async () => {
      leaseRateDetails.value.leaserateValue = convertToNumber(leaseRateDetails.value.leaserateValue)
      leaseRateDetails.value.leaserateType =
        leaseRateDetails.value.leaserateType == '€' ? '$' : leaseRateDetails.value.leaserateType
      leaseRateDetails.value.fuelValue = convertToNumber(leaseRateDetails.value.fuelValue)
      leaseRateDetails.value.fuelType = leaseRateDetails.value.fuelType == '€' ? '$' : leaseRateDetails.value.fuelType
      leaseRateDetails.value.months36 = convertToNumber(leaseRateDetails.value.months36)
      leaseRateDetails.value.months48 = convertToNumber(leaseRateDetails.value.months48)
      leaseRateDetails.value.months60 = convertToNumber(leaseRateDetails.value.months60)
      leaseRateDetails.value.biggerTyres = convertToNumber(leaseRateDetails.value.biggerTyres)
      await store.dispatch(superAdminCompanyActionTypes.ADD_COMPANY_LEASERATE, {
        companyId: superAdminCompanyId.value,
        body: leaseRateDetails.value
      })
    }
    const addCompanyAdmin = async () => {
      await store.dispatch(superAdminCompanyActionTypes.ADD_COMPANY_USER, {
        companyId: superAdminCompanyId.value,
        body: firstCompanyAdminDetails,
        lang: localStorage.getItem('locale') || 'nl'
      })
    }
    const saveCompany = async () => {
      const formData = new FormData()
      formData.append('name', JSON.stringify(companyDetails.name))
      formData.append('email', JSON.stringify(companyDetails.email))
      formData.append('phoneNumber', JSON.stringify(companyDetails.phoneNumber))
      formData.append('address', JSON.stringify(companyDetails.address))
      formData.append('postalCode', JSON.stringify(companyDetails.postalCode))
      formData.append('city', JSON.stringify(companyDetails.city))
      formData.append('userAgreementCopyEmail', JSON.stringify(removeType(companyDetails.userAgreementCopyEmail)))
      formData.append('quotationRequestEmail', JSON.stringify(removeType(companyDetails.quotationRequestEmail)))
      formData.append('logo', companyDetails.logo as File)
      await store.dispatch(superAdminCompanyActionTypes.ADD_COMPANY, { body: formData })
    }
    const discardCompany = () => {
      router.push({ name: 'Dashboard' })
    }
    const closePopup = () => {
      store.commit(MutationTypes.SET_ISCOMPANYUPDATED, false)
    }
    onUnmounted(() => {
      store.commit(superAdminMutationTypes.SET_COMPANY_ERROR, '')
      store.commit(superAdminMutationTypes.SET_USER_ERROR, '')
      store.commit(superAdminMutationTypes.SET_SUPERADMIN_COMPANYID, null)
      store.commit(superAdminMutationTypes.SET_LEASE_RATE_ERROR, '')
    })
    const onCheckSendInviteUser = (value) => {
      firstCompanyAdminDetails.sendInvite = value.option.target.checked ? 1 : 0
    }
    const toggleSection = (index) => {
      const closeAllOther = sectionToggleValues.value.map(function (item, itemIndex) {
        if (itemIndex === index) {
          item = !item
        } else {
          item = false
        }
        return item
      })
      sectionToggleValues.value = closeAllOther
    }
    const goToNext = async (type, index) => {
      let sectionID = ''
      if (type === 'step1' && validateSections()) {
        await saveCompany()
        if (superAdminCompanyId.value) {
          sectionToggleValues['value'][index] = false
          sectionToggleValues['value'][index + 1] = true
          sectionID = 'adjustLeaseRateDetails'
        }
      } else if (type === 'step2' && validateLeaseRate()) {
        await addLeaseRates()
        if (leaseRateError.value == '') {
          sectionToggleValues['value'][index] = false
          sectionToggleValues['value'][index + 1] = true
          sectionID = 'firstAdminCompanyDetails'
          isCompanyAdminDisabled.value = false
        }
      } else if (type === 'step3' && validateFirstCompanyAdmin()) {
        await addCompanyAdmin()
      }
      if (sectionID !== '')
        setTimeout(() => {
          scrollToSection(sectionID)
        }, 300)
    }
    const addRow = (item) => {
      if (item['type'] == 'agreementMail') {
        selectedRow.value = companyDetails.userAgreementCopyEmail
        selectedRow.value.push({
          email: '',
          type: 'add'
        })
      } else if (item['type'] == 'quotationEmail') {
        selectedRow.value = companyDetails.quotationRequestEmail
        selectedRow.value.push({
          email: '',
          type: 'add'
        })
      }
      selectedRow.value[item['index']]['type'] = 'delete'
    }
    const deleteRow = (item) => {
      if (item['type'] == 'agreementMail') {
        selectedRow.value = companyDetails.userAgreementCopyEmail
      } else if (item['type'] == 'quotationEmail') {
        selectedRow.value = companyDetails.quotationRequestEmail
      }
      selectedRow.value.splice(item['index'], 1)
    }
    return {
      file,
      fileChange,
      removeImage,
      companyDetails,
      loader,
      discardCompany,
      onInputCompanyDetails,
      companyDetailsErrors,
      closePopup,
      firstCompanyAdminDetails,
      onInputFirstCompanyAdmin,
      firstCompanyAdminDetailsErrors,
      onCheckSendInviteUser,
      operatorItems,
      leaseRateDetails,
      selectleaseRateOperator,
      onUpdateLeaseRateDetails,
      ConvertToCommas,
      typeItems,
      selectLeaseRateType,
      selectfuelOperator,
      selectfuelType,
      companyError,
      userError,
      sectionToggleValues,
      toggleSection,
      goToNext,
      superAdminCompanyId,
      isCompanyAdminDisabled,
      leaseRateError,
      leaseRateErrors,
      addRow,
      deleteRow,
      onColumnInput
    }
  },
  components: {
    InputElement,
    Loader,
    Button,
    OptionsCheckboxes,
    Dropdown,
    toggleableContent
  }
})
